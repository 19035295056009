<template>
	<div class="groupManage">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click.stop="dialogVisible = true, dialogType = 1, form = {
                    target: []
                }">新增科目</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="科目名称" prop="name" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click.stop="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="30%">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="科目名称" required>
					<el-input type="input" v-model="form.name" placeholder="请输入科目名称" maxlength="50"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchForm: {},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,

				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				form: {
					name: ""
				}
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/financial/income/type/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 编辑弹窗
			openDialog(item) {
				this.form = {
					...item
				}
			},
			// 确定
			confirmFn() {
				if (!this.form.name) {
					this.$message.error('请输入科目名称');
					return
				}

				let url = this.dialogType == 1 ? '/erp/v1/financial/income/type/add' :
					'/erp/v1/financial/income/type/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.groupManage {
		.notice {
			background: #fff;
			margin-bottom: 20px;
			padding: 20px;
			border-radius: 10px;
		}
	}
</style>